import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LoginRoutingModule } from "./login-routing.module";
import { LoginComponent } from "./login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProgressBarModule } from "primeng/progressbar";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from "primeng/progressspinner";

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // ProgressBarModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    ProgressSpinnerModule,
  ],
  declarations: [LoginComponent],
})
export class LoginModule {}
