// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // baseurl: "http://localhost:4200/",
  //  baseurl : 'http://kcpbackend.slrobotics.org/',

  // imageServer : 'http://kcpbackend.slrobotics.org/storage/',
  // imageServer: "http://localhost:8000/storage/",
  // imageServer: "https://unicoan.com/kcwsc-demo-backend/public/storage/",
  imageServer : "https://kcwscbackend.slrobotics.org/public/storage/",

  // apiserverLaravel : 'http://kcpbackend.slrobotics.org/api',
  // apiserverLaravel: "http://localhost:8000/api",
  // apiserverLaravel: "https://unicoan.com/kcwsc-demo-backend/public/api",
  apiserverLaravel : "https://kcwscbackend.slrobotics.org/public/api",

  // apiserverNode: "http://kcwsc.slrobotics.org:4000/api",
  // apiserverNode: "http://localhost:4000/api",
  // apiserverNode: "https://unicoan.com/kcwsc-demo-backend/public/api",
  apiserverNode : "https://kcwscbackend.slrobotics.org/public/api"
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
