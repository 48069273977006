import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { User } from "../models/user.model";
import * as moment from "moment";
import { Observable, throwError, BehaviorSubject } from "rxjs";

import { tap, map, retry, catchError } from "rxjs/operators";
import { HttpHeaders, HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  getUser(id: any, page: any) {
    throw new Error("Method not implemented.");
  }

  baseUrl = environment.apiserverLaravel;
  baseUrl1 = environment.apiserverNode;
  userSession: User;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(private httpClient: HttpClient) {
    //getting current user
    this.user.subscribe((data) => {
      this.userSession = data;
      console.log(this.userSession);
    });
  }

  private waitingSession = new BehaviorSubject(false); 
  waiting : Observable<boolean> = this.waitingSession.asObservable();

  private session = new BehaviorSubject(new User());
  user: Observable<User> = this.session.asObservable();

  updatedDataSelection(data: User) {
    this.session.next(data);
  }
  
  updatedwaitingSession(data: boolean) {
    this.waitingSession.next(data);
  }  

  logout(): Observable<string> {
    let url = `${this.baseUrl}/logout`;

    return this.httpClient
      .get<string>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMemberByUser(data?: any, page?: any): Observable<User> {
    // let url = `${this.baseUrl}/user/userList/?page=1`;
    let url = `${this.baseUrl}/user/userList?page=1`;

    if (page > 0) {
      // url = `${this.baseUrl}/user/userList/?page=` + page;
      url = `${this.baseUrl}/user/userList?page=` + page;
    }

    return this.httpClient
      .post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  // getMemberByUser(data?: any, page?: any): Observable<User> {
  //   let url = `${this.baseUrl1}/user/userList/1`;

  //   if (page > 0) {
  //     url = `${this.baseUrl1}/user/userList/` + page;
  //   }

  //   return this.httpClient
  //     .post<User>(url, JSON.stringify(data), this.httpOptions)
  //     .pipe(retry(1), catchError(this.handleError));
  // }

  validateUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/login`;
    debugger;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  sendPasswordResetLink(data: any) {
    let url = `${this.baseUrl}/sendPasswordResetLink`;
    debugger;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  public isLoggedIn() {
    var yes = moment().isBefore(this.getExpiration());
    return yes;
  }

  getExpiration() {
    // this.user.subscribe(data =>{
    //   const expiration = data.tokenexpire;
    //   //const expiresAt = JSON.parse(expiration);
    //   return (expiration);
    // });

    const expiration = this.userSession.tokenexpire;
    return expiration;
  }

  getLoggedUser(): Observable<boolean> {
    let url = `${this.baseUrl}/loggeduser`;
    var data = {};

    this.user.subscribe((res) => {
      data = {
        token: res.token,
      };
    });

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  endSession(): Observable<boolean> {
    let url = `${this.baseUrl}/logout`;

    var data = {};

    this.user.subscribe((res) => {
      data = {
        token: res.token,
      };
    });

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveUser(data: any): Observable<User> {
    let url = `${this.baseUrl}/register`;
    debugger;
    if (data["id"] != 0) {
      url = `${this.baseUrl}/update/` + data["id"];
    }

    return this.httpClient
      .post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  resetPw(data: any): Observable<any> {
    let url = `${this.baseUrl}/resetpw`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    debugger;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      debugger;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      debugger;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
