import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'src/app/shared/core/auth-guard.service';
import { ComponentRoutingModule } from './component-routing.module';
import { ComponentComponent } from './component.component';


@NgModule({
  declarations: [ComponentComponent],
  imports: [
    CommonModule,
    ComponentRoutingModule
  ],
  providers: [AuthGuard]
})
export class ComponentModule { }
