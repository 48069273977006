import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { User } from "src/app/shared/models/user.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { enableProdMode } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { HttpClient } from "@angular/common/http";

enableProdMode();

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  newForm: FormGroup;
  username: string;
  password: string;
  isWaiting: boolean = false;
  userSession: User;

  msgs: Message[] = [];
  submitted: boolean;
  meta: any;
  userList: User[] = [];

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private _router: Router,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.authService.waiting.subscribe(data=>{
      this.isWaiting = data;
    });
  }

  ngOnInit() {
    this.isWaiting = false;
    this.msgs = [];
    this.buildForm();
    this.getUser();

    //getting current user
    // this.authService.user.subscribe(data => {
    //   this.userSession = data;
    // });
  }

  validateUser() {
    this.isWaiting = true;
    this.authService.updatedwaitingSession(true);
    var credentials = {
      email: this.newForm.get("email").value,
      password: this.newForm.get("password").value,
    };

    this.authService.validateUser(credentials).subscribe(
      (res) => {
        console.log(res);
        debugger;
        this.isWaiting = false;
        this.authService.updatedwaitingSession(false);
        this.authService.updatedDataSelection({
          id: res["id"],
          name: res["name"],
          email: res["email"],
          password: "",
          image: res["image"],
          role: res["role"],
          contact: res["contact"],
          token: res["access_token"],
          tokenexpire: moment().add(JSON.parse(res["expires_in"]), "second"),
        });

        //this._router.navigate(['/component']);
        this._router.navigate(["/base/history"]);
      },
      (err) => {
        this.isWaiting = false;
        this.authService.updatedwaitingSession(false);
        this.msgs = [];
        this.msgs.push({severity:'error', detail:"Username or password is incorrect!"});
      }
    );
  }
  buildForm() {
    //form group for register new custormer
    this.newForm = this.fb.group({
      id: [""],
      name: ["0"],
      email: ["", Validators.required],
      password: ["", Validators.required],
      image: [""],
      role: ["0"],
      contact: ["0"],
      status: ["1"],
    });
  }
  Submit(value: string) {
    if (this.newForm.invalid) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please fill required fields!",
      });
      return true;
    }

    this.authService.validateUser(this.newForm.value).subscribe((res) => {
      var tempId = res["data"]["id"];
      this.getUser();
      this.messageService.add({
        severity: "success",
        summary: "Success",
        detail: "successfully!",
      });

      this._router.navigate(["/base/" + res["data"]["id"]]);
    });
    this.submitted = true;
  }
  getUser(id?, page?) {
    // this.authService.getUser(id , page).subscribe(res =>{
    //   this.userList = res['data'];
    //   this.meta = res['meta'];
    // });
  }
}
